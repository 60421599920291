import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`While this would be our normal Make Up Day we are moving it tomorrow
since the Governor has issued mandatory gym closures.  Today we will
have classes at 6:00am, 10:30am, 12:00pm, 3:00pm & 4:00pm.  We must
close by 5:00pm.`}</em></p>
    <p>{`Shoulder Press 4×6\\@80% 1RM`}</p>
    <p>{`Strict Pullups 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`42-Calorie Assault Bike`}</p>
    <p>{`21-Bench Press (135/80)(Rx+ 165/100)`}</p>
    <p>{`21-Pullups`}</p>
    <p>{`30-Calorie Assault Bike`}</p>
    <p>{`15-Bench Press`}</p>
    <p>{`15-Pullups`}</p>
    <p>{`18-Calorie Assault Bike`}</p>
    <p>{`9-Bench Press`}</p>
    <p>{`9-Pullups`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p">{`*`}{`We have started a Ville Group on Facebook so please “like” our
CrossFit the Ville page and look for our members only group.  At home
programming will be posted daily as well as gym updates among other
things.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      